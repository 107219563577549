<template>
  <skeleton
    v-if="student.isLoading"
    borderRadius="10px"
    height="10rem"
    class="skeleton-dark p-3"
  />
  <div
    v-else
    class="
      student-information
      p-4
      pe-5
      bg-primary-5
      border-r-10
      d-flex
      justify-content-between
      gap-3
    "
  >
    <i
      class="pi pi-arrow-left text-primary-1 fs-4 fw-bold pointer"
      @click="goPageStudents"
    ></i>
    <div class="d-flex gap-4 w-100 align-items-center">
      <div class="header-user- header-user-big w-100 text-center">
        <div class="position-relative text-center user-picture">
          <avatar
            :image="
              student.data.student_info?.photo
                ? student.data.student_info?.photo
                : '/assets/images/avatar.png'
            "
            shape="circle"
          />
          <div
            class="
              user-status
              w-auto
              bg-white
              py-1
              px-2
              shadow-sm
              d-flex
              gap-2
              align-items-center
            "
          >
            <div
              class="status"
              :class="
                status[student.data.status]
                  ? status[student.data.status].class
                  : ''
              "
            ></div>
            <span class="fs-6">{{
              status[student.data.status]
                ? status[student.data.status].text
                : "cargando..."
            }}</span>
          </div>
        </div>
      </div>
      <div class="w-100">
        <h1 class="fs-3 fw-bold">
          {{ student.data.student_info.name +' '+  student.data.student_info.last_name}}
        </h1>
        <div class="d-flex gap-3">
          <span class="fs-6">{{ student.data.student_info.age }} years</span>
          <span class="fs-6">|</span>
          <span class="fs-6">{{
            student.data.student_info.gender
              ? student.data.student_info.gender
              : "-"
          }}</span>
        </div>
        <p class="fs-6 my-1">
          {{
            student.data.student_info?.city
              ? student.data.student_info.city
              : ""
          }}
          {{
            student.data.student_info?.timezone_change
              ? student.data.student_info?.timezone_change
              : "-"
          }}
        </p>
        <h2 class="fs-6 my-1">
          Member since:
          {{
            student.data.student_info?.date_joined
              ? formatDate(student.data.student_info?.date_joined, "month")
              : "-"
          }}
        </h2>
      </div>
    </div>
    <div class="container-level text-center">
      <div class="border-primary-1 py-1 px-4 h-content border-r-10 level">
        <progress-level-component :title="'Level'" />
      </div>
      <button
        type="button"
        class="btn text-secondary-1 fs-1-1 text-hover-secondary-1 py-1 px-0"
        @click="toggleUpgradeLevelModal(true)"
      >
        Upgrade level
      </button>
    </div>
  </div>
</template>

<script>
import ProgressLevelComponent from "@/shared/components/Levels/ProgressLevelComponent";
import useInformation from "@/modules/teachers/composables/Student/useInformation";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  name: "InformationComponent",
  components: {
    ProgressLevelComponent,
  },
  setup() {
    const { goPageStudents, toggleUpgradeLevelModal, student } =
      useInformation();

    const { status } = usePrivateLayout();
    return {
      goPageStudents,
      status,
      toggleUpgradeLevelModal,
      student,
      formatDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-level {
  max-width: 220px;
  width: 100%;
}
</style>
