<template>
  <information-component />
  <div class="mt-4 grid-">
    <about-component />
    <progress-component />
    <class-progress-component />
  </div>
  <div class="mt-4">
    <feedback-component />
  </div>
</template>

<script>
import InformationComponent from "@/modules/teachers/components/Student/InformationComponent";
import AboutComponent from "@/modules/teachers/components/Student/AboutComponent";
import ProgressComponent from "@/modules/teachers/components/Student/ProgressComponent";
import FeedbackComponent from "@/modules/teachers/components/Student/FeedbackComponent";
import ClassProgressComponent from "@/modules/teachers/components/Student/ClassProgressComponent";
import useStudent from "@/modules/teachers/composables/Student/useInformation" 
export default {
  name: "StudentView",
  components: {
    InformationComponent,
    AboutComponent,
    ProgressComponent,
    FeedbackComponent,
    ClassProgressComponent,
  },
  setup() {
    const {  getStudent } = useStudent()
    getStudent()
    return {};
  },
};
</script>

<style lang="scss" scoped>
.grid- {
  display: grid;
  grid-template-columns: 24rem repeat(2, 2fr);
  gap: 1rem;
}
</style>
