<template>
  <data-table :value="students.data" dataKey="id_student" :loading="students.isLoading" :rowHover="true"
    :paginator="true" :rows="rows" :first="firstTable" @page="onPage" :totalRecords="students.data.length"
    v-model:filters="filtersStudents" filterDisplay="menu" size="normal" :globalFilterFields="fields" responsiveLayout="scroll"
    :rowsPerPageOptions="[rows * 1, rows * 5, rows * 10]">
    <template #header>
      <div class="d-flex align-items-center justify-content-between gap-4">
        <div class="d-flex flex-column gap-2" :style="{ width: '30%' }">
          <h1 class="text-primary-1 fw-bold">My students</h1>
          <div class="d-flex align-items-center gap-1">
            <input-text v-if="isShowFieldSearch" v-model="filtersStudents['global'].value"
              placeholder="Search by name, email, level ..." class="rounded-0" />
            <i class="
                pi pi-search
                py-2
                px-3
                bg-primary-1
                text-white
                fs-5
                icon-search
                pointer
                border-r-10
                overflow-hidden
              " @click="showFieldSearch"></i>
          </div>
        </div>
        <div class="d-flex flex-column gap-4">
          <router-link :to="{ name: 'teacher-students-feedback' }" class="text-decoration-none">
            <button class="
                btn
                text-secondary-1
                border-secondary-1
                bg-hover-light-secondary-1
                p-2
                px-5
              ">
              View Students
            </button>
          </router-link>

          <button class="
              btn
              text-primary-2 text-hover-primary-2
              border-primary-2
              px-4
            ">
            <div class="d-flex justify-content-between align-items-center" @click="(e) => showFilterOptions(e)">
              Filters
              <i class="pi pi-chevron-down"></i>
            </div>
          </button>
        </div>
      </div>
    </template>

    <template #empty> No students found. </template>

    <column selectionMode="multiple" headerStyle="width: 3rem"></column>

    <column v-for="(header, index) in headers" :key="index" :field="header.field" :header="header.name"
      :sortable="header.sortable" style="min-width: 14rem"  :showFilterMatchModes="false">
      <template #body="{ data }">
        <div v-if="header.field === 'name'">
          <p>{{ data['name'] + ' ' + data['last_name'] }}</p>
          <button  type="button"
            class="btn text-secondary-1 text-hover-secondary-1 py-1 px-0" @click="goPageStudent(data.id_student)">
            See profile
          </button>
        </div>
        <p v-else-if="header.field !== 'status'">{{ data[header.field] }}</p>

        <div class="
              bg-white
              py-1
              px-3
              bl-shadow
              d-flex
              gap-2
              rounded-pill
              w-content
              align-items-center
            " v-if="header.field === 'status'">
            <div class="status" :class="status[data.status] ? status[data.status].class : 'bg-neutro-2'"></div>
            <span class="text-neutro-1" v-if="data.status">{{
                status[data.status] ? status[data.status].text : "Offline"
            }}</span>
            <span class="text-neutro-1" v-if="!data.status">Offline</span>
          </div>
      </template>
      <template #filter="{ filterModel }" v-if="header.filter">
        <div class="d-block" v-if="header.field === 'status'">
          <div class="d-flex align-items-center mb-1 gap-2">
            <Checkbox name="status" value="online" v-model="filterModel.value" />
            <label>Online</label>
          </div>
          <div class="d-flex align-items-center mb-1 gap-2">
            <Checkbox name="status" value="offline" v-model="filterModel.value" />
            <label>Offline</label>
          </div>
        </div>
        <div class="d-block" v-if="header.field === 'gender'">
          <div v-for="(gender, index) in getListings.genders" :key="index" class="d-flex align-items-center mb-1 gap-2">
            <Checkbox name="status" :value="gender.value" v-model="filterModel.value" />
            <label>{{ gender.value }}</label>
          </div>
        </div>
        <div class="d-block" v-if="header.field === 'age'">
          <div class="d-block">
            <input-text type="number" name="age" v-model="filterModel.value" placeholder="Search by age" />+
          </div>
        </div>
        <div class="d-block" v-if="header.field === 'level'">

          <div v-for="(level, index) in getListings.levels.data" :key="index"
            class="d-flex align-items-center mb-1 gap-2">
            <Checkbox name="level" :value="level.name" v-model="filterModel.value" />
            <label>{{ level.name }}</label>
          </div>
        </div>
      </template>
    </column>

    <overlay-panel ref="filterOptions" style="width: 192px" class="filter-options-students border-primary-2">
      <div class="option-filter d-flex gap-2 py-1 px-3" v-for="(option, index) in listFilterOptions" :key="index">
        <input type="checkbox" class="check-filter" @change="
          (e) =>
            onColumnToggle(e, {
              field: option.field,
              name: option.name,
            })
        " :id="option.field" :value="option.field" :name="option.field" :checked="isChecked(option.field)" />

        <span>{{ option.name }}</span>
      </div>
    </overlay-panel>
  </data-table>
  <!-- <paginator
    v-if="students.length > 10"
    :rows="10"
    :totalRecords="1"
    :rowsPerPageOptions="[10, 20, 30]"
    class="mt-3 bg-primary-5 border-r-10"
  ></paginator> -->
</template>

<script>
import useTable from "@/modules/teachers/composables/Student/useTable";

export default {
  name: "MyStudentsView",
  components: {},
  setup() {
    return {
      ...useTable(),
    };
  },
};
</script>


<style lang="scss" scoped>
.p-paginator-first {
  margin-left: 0% !important;
}
</style>
