<template>
   <skeleton
      v-if="student.isLoading"
      borderRadius="10px"
      height="20rem"
      class="skeleton-dark p-3"
    />
  <div v-else class="bg-white p-4 border-r-10">
    <div class="mb-2 d-flex gap-2">
      <p class="fs-1-1 fw-bold d-inline w-50">Learning goal:</p>
      <p class="fs-1-1 d-inline">{{learningGoal}}</p>
    </div>
    <p class="fs-1-1 fw-bold">About me:</p>
    <div class="field-blue w-100">
      <Textarea
        :autoResize="true"
        class="w-100 fs-6 border-r-10 mt-2"
        placeholder="About me"
        :modelValue="student.data.student_info.my_goal_about"
        :value="student.data.student_info.my_goal_about"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import useInformation from "@/modules/teachers/composables/Student/useInformation";
import { computed } from "vue";

export default {
  name: "AboutComponent",
  setup(){
    const { student } = useInformation()

    const learningGoal = computed(() => student.value.data.student_info.my_goal_option.split(':')[1])

    return {
      student, 
      learningGoal
    }
  },
};
</script>