<template>
  <div class="bg-white p-4 border-r-10">

    <header v-if="student - isLoading" class="d-flex align-items-center justify-content-between mb-3">
      <h1 class="text-primary-1 fs-3 fw-bold">Teacher's notes</h1>
      <button @click="seeMoreAll(student.data?.comments.length)" v-if="student.data?.comments.length > 10"
        class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 p-2 px-5">
        see all
      </button>
    </header>
    <skeleton v-if="student.isLoading" borderRadius="10px" height="20rem" class="skeleton-dark p-3" />
    <div v-else class="table-feedback-student">
      <data-Table 
        :value="student.data.comments.slice(0, initialShowTeachersNote)" 
        dataKey="id" 
        :rowHover="true"
        filterDisplay="menu" 
        :globalFilterFields="fields" size="normal" 
        :resizableColumns="true" 
        columnResizeMode="fit"
        responsiveLayout="scroll" 
        :rows="20" 
        v-model:filters="filters">

        <template #empty> No comments found. </template>
        <column v-for="(header, index) in headersComments" 
          :key="index" 
          :field="header.field" 
          :header="header.name"
          :sortable="header.sortable" 
          style="min-width: 14rem" 
          :showFilterMatchModes="false">
          
          <template #body="{ data }">
            <p v-if="header.field !== 'lesson_end' && header.field !== 'feedback'">{{ data[header.field] }}</p>
            <template v-else-if="header.field === 'feedback'">
              <div class="d-flex gap-3 align-items-center">
                <div class="d-block">
                  <p class="text-truncate mb-2" style="width:350px"
                    v-if="data.feedback.find(p => p.type === 'content')?.answer">
                    {{ data.feedback.find(p => p.type === 'content')?.answer?.substring(0, 47) }}</p>
                  <p class="text-truncate" style="width:350px"
                    v-if="data.feedback.find(p => p.type === 'content')?.answer">{{
                        data.feedback.find(p => p.type === 'content')?.answer?.substring(47, 100)
                    }}</p>
                  <p v-else style="width:350px"> There is no comment from the teacher.</p>
                </div>
                <button @click="() => toggleModalStudent(data)"
                  class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-3 bl-shadow w-auto">
                  Show more
                </button>
              </div>
            </template>
            <p v-else>{{ formatDate(data.lesson_end, 'american') }}</p>

          </template>
          <template #filter="{ filterModel }" v-if="header.filter">
            <div class="d-block" v-if="header.field === 'lesson_start'">
              <input-text type="text" name="lesson_start" placeholder="Search by date" v-model="filterModel.value" />
            </div>
            <div class="d-block" v-if="header.field === 'teacher'">
              <input-text type="text" name="teacher" placeholder="Search by Teacher Name" v-model="filterModel.value" />
            </div>
          </template>
        </column>
      </data-Table>
      <button v-if="initialShowTeachersNote < student.data?.comments.length"
        @click="seeMoreAll(student.data.comments?.length, 'more')" class="
          btn
          text-primary-1
          border-primary-1
          p-2
          px-5
          d-flex
          align-items-center
          mx-auto
        ">
        <i class="pi pi-plus-circle pe-2 fs-5"></i>
        See more
      </button>
    </div>
  </div>
</template>

<script>
import useFeedback from "@/modules/teachers/composables/Student/useFeedback";
import useInformation from "@/modules/teachers/composables/Student/useInformation";
import useTable from "@/modules/teachers/composables/Student/useTable";
import formatDate from "@/shared/utils/changeTexts/formatDate"

export default {
  name: "FeedbackComponent",
  setup() {
    const { handleShowStudentFeedback, toggleModalStudent } = useFeedback();
    const { student } = useInformation();
    const { headersComments, fieldsComments, filters, seeMoreAll, initialShowTeachersNote } = useTable();
    return {
      seeMoreAll,
      initialShowTeachersNote,
      handleShowStudentFeedback,
      student,
      toggleModalStudent,
      formatDate,
      headersComments,
      filters,
      fieldsComments,
    };
  },
};
</script>
