<template>
 <skeleton
    v-if="student.isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark p-3"
  />
  <div v-else class="bg-primary-5 p-4 border-r-10">
    <h2 class="text-primary-0 fs-4 fw-bold mb-3">Class progress</h2>
    <div class="d-flex justify-content-between gap-1">
      <p class="fw-bold fs-1-1 lh-1">Total classes/hours</p>
      <p class="fw-bold fs-1-1 lh-1 text-primary-1">
        {{ dashboard.totalClasses }}/{{ dashboard.totalClasses / 2 }}hrs
      </p>
    </div>
    <hr />
    <p class="fw-bold fs-1-1 lh-1 text-primary-0 mb-3">
      Classes taken this week
    </p>
    <progress-week-component />
  </div>
</template>

<script>
import ProgressWeekComponent from "@/shared/components/Levels/ProgressWeekComponent";
import useDashboard from "@/modules/teachers/composables/Dashboard/useDashboardMain";
import useInformation from "@/modules/teachers/composables/Student/useInformation";

export default {
  components: {
    ProgressWeekComponent,
  },
  setup() {
    const { dashboard } = useDashboard();
    const { student } = useInformation();

    return {
      dashboard,
      student
    };
  },
};
</script>

