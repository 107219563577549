<template>
  <skeleton
    v-if="student.isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark p-3"
  />
  <div v-else class="bg-primary-5 p-4 border-r-10">
    <h2 class="text-primary-0 fs-4 fw-bold mb-3">Last class info</h2>
    <div class="field-grid">
      <p class="fw-bold fs-1-1 lh-1">Last teacher</p>
      <p class="text-primary-1 fs-1-1 lh-sm">
        {{ student.data.last_class_info.teacher }}
      </p>
    </div>
    <div class="field-grid">
      <p class="fw-bold fs-1-1 lh-1">Bright path’s progress</p>
      <p class="text-primary-1 fs-1-1 lh-sm">
        {{ student.data.last_class_info.bright_paths_progress }}
      </p>
    </div>
    <div class="field-grid field-blue">
      <p class="fw-bold fs-1-1 lh-sm">Class summary</p>
      <Textarea
        :autoResize="true"
        class="w-100 fs-6 border-r-10"
        placeholder="Resume"
        :model-value="student.data.last_class_info.class_resume"
        :value="student.data.last_class_info.class_resume"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import useInformation from "@/modules/teachers/composables/Student/useInformation";
export default {
  name: "ProgressComponent",
  setup() {
    const { student } = useInformation();
    return {
      student,
    };
  },
};
</script>

<style lang="scss" scoped>
.field-grid {
  display: grid;
  grid-template-columns: 7rem 1fr;
  column-gap: 10px;
  margin-bottom: 0.6rem;
}
</style>